import Banner from "../containers/WebApp/Industries/Banner";
import Section from "../containers/WebApp/Industries/Section";
import SecurityCertifications from "../containers/WebApp/Industries/SecurityCertifications";
import PanelsMemberships from "../containers/WebApp/Industries/PanelsMemberships";
import SEO from "../components/SEO";
import React from "react";

const Industries = () => {
  return (
    <>
      <SEO title="Industries" />
      <Banner />
      <Section />
      <SecurityCertifications />
      <PanelsMemberships />
    </>
  );
};

export default Industries;
