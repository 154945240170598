import Box from "../../../../common/components/Box";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { BANNER_INDUSTRIES_DATA } from "../../../../common/data/WebApp";

import { Link } from "gatsby";
import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import Typewriter from "typewriter-effect";
import BannerArea, { Col, TypewriterWrapper } from "./banner.style";

const Banner = () => {
  const { title, text, button, image, tagline } = BANNER_INDUSTRIES_DATA;

  return (
    <BannerArea id="banner_section">
      <Container className="Container">
        <Col>
          <TypewriterWrapper>
            <strong>
              <Typewriter
                options={{
                  strings: title,
                  autoStart: true,
                  loop: false,
                  cursor: "_",
                }}
              />
            </strong>
          </TypewriterWrapper>
          <Text as="p" content={text} />
          <Box className="ButtonWrap">
            <Link className="Button" to={button.link}>
              {button.label}
              <Icon size={18} icon={androidArrowForward} />
            </Link>
          </Box>
        </Col>
      </Container>
      <Box className="bannerImage">
        {image.map(({ src }, index) => (
          <img src={src} alt="" key={`banner-image-key-${index}`} />
        ))}
      </Box>
    </BannerArea>
  );
};

export default Banner;
