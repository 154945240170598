import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_INDUSTRIES } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, { Row, ColumnContents } from "./section.style";
const SectionA = () => {
  const { sections } = SECTION_INDUSTRIES;
  // const { title, text, button } = blockTitle;
  return (
    <ColumnContents id="disp-membership">
      <SectionArea>
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections[0].title} />
              <Text as="p" content={sections[0].text} />
              {/* <Link className="button" to={sections[0].button.link}>
                <span>
                  {sections[0].button.label}
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link> */}
            </Box>
            <Box className="postsWrap">
              <img src={sections[0].image} alt="x-rd" />
            </Box>
          </Row>
        </Container>
      </SectionArea>
    </ColumnContents>
  );
};

export default SectionA;
